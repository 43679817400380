import React, { useContext, useEffect, useState } from 'react'
import { useInView } from "react-intersection-observer"
import Image from 'next/image';
import { isMobileContext } from '@/contexts/isMobileContext';

const LoadingPage = ({ error, articlePage, pageLoading }) => {
    const [ref, inView] = useInView({ threshold: 1, triggerOnce: true })
    const { isMobile } = useContext(isMobileContext)
    const [finishLoading, setFinishLoading] = useState(0)
    useEffect(() => {
        let timeOut;
        if (pageLoading) {
            document.body.classList.add("no-scroll")
        } else {
            document.body.classList.remove("no-scroll")
            timeOut = setTimeout(() => {
                setFinishLoading(true)
            }, 500)
            // return ()=> clearTimeout(timeOut)
        }
    }, [pageLoading])
    return (
        <div
            className={`loader-page ${!pageLoading ? "end" : ""} ${finishLoading && "d-none"}`} ref={ref}>
            <div>
                <svg
                    className={`view ${inView && "in-view"}`}
                    width="220" height="35" viewBox="0 0 333 57" fill="none" xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M108.14 0.91V17.54H88.52V0.91H83.88V36.3H88.52V18.94H108.14V36.3H112.78V0.91H108.14Z" fill="#fff" />
                    <path d="M133.39 18.76L122.91 0.91H117.68L129.19 20.43V36.3H133.83V20.85L145.51 0.91H143.87L133.39 18.76Z" fill="#fff" />
                    <path d="M159.19 34.89H154.47V2.32H159.19C167.11 2.32 173.65 8.55 173.65 18.61C173.65 28.67 167.11 34.9 159.19 34.9V34.89ZM149.83 0.91V36.3H159.29C169.95 36.3 178.52 29.08 178.52 18.6C178.52 8.12 169.96 0.91 159.3 0.91H149.83Z" fill="#fff" />
                    <path d="M184.31 36.3H204.46V34.9H188.95V18.95H201.93V17.54H188.95V2.32H204.46V0.91H184.31V36.3Z" fill="#fff" />
                    <path d="M230.25 19H226.94V2.32H230.25C236.64 2.32 239.27 5.16 239.27 10.66C239.27 16.16 236.64 19 230.25 19ZM231.55 0.91H222.3V36.3H226.94V20.4H231.55C238.38 20.4 244.19 17.51 244.19 10.65C244.19 3.79 238.38 0.9 231.55 0.9V0.91Z" fill="#fff" />
                    <path d="M248.21 24.18L254.78 7.84L261.35 24.18H248.21ZM241.9 36.3H243.36L247.66 25.59H261.92L266.22 36.3H271.09L256.5 0L241.91 36.3H241.9Z" fill="#fff" />
                    <path d="M281.01 2.32H284.71C291.09 2.32 293.73 5.16 293.73 10.66C293.73 16.16 291.1 19 284.71 19H281.01V2.32ZM286.01 20.41C292.84 20.41 298.65 17.52 298.65 10.66C298.65 3.8 292.84 0.91 286.01 0.91H276.37V36.3H281.01V21.37L299.12 36.3H301.23L281.94 20.4H286.01V20.41Z" fill="#fff" />
                    <path d="M318.52 14.1L328.76 0.91H326.91L310.34 22.33V0.91H305.7V36.3H310.34V24.68L315.6 17.88L327.41 36.3H332.9L318.52 14.1Z" fill="#fff" />
                    <path d="M86.4804 46.6499C89.1604 46.6499 91.3304 48.8199 91.3304 51.4999C91.3304 54.1799 89.1604 56.3499 86.4804 56.3499H83.6504V46.6399L86.4804 46.6499ZM89.7204 51.4999C89.7204 49.7099 88.2704 48.2599 86.4804 48.2599H85.2604V54.7299H86.4804C88.2704 54.7399 89.7204 53.2899 89.7204 51.4999Z" fill="#fff" />
                    <path d="M121.9 48.2699V50.6999H126.79V52.3199H121.9V54.7399H126.79V56.3599H120.28V46.6499H126.79V48.2699H121.9Z" fill="#fff" />
                    <path d="M97.4501 48.2699V50.6999H102.34V52.3199H97.4501V54.7399H102.34V56.3599H95.8301V46.6499H102.34V48.2699H97.4501Z" fill="#fff" />
                    <path d="M185.74 48.2699V50.6999H190.63V52.3199H185.74V54.7399H190.63V56.3599H184.12V46.6499H190.63V48.2699H185.74Z" fill="#fff" />
                    <path d="M116.07 46.6499L111.5 56.4099L106.95 46.6499H108.91L111.5 52.2099L114.11 46.6499H116.07Z" fill="#fff" />
                    <path d="M138.301 54.7399V56.3599H132.641V46.6499H134.261V54.7399H138.301Z" fill="#fff" />
                    <path d="M152.171 51.4999C152.171 54.1799 150.001 56.3599 147.321 56.3599C144.641 56.3599 142.471 54.1899 142.471 51.4999C142.471 48.8099 144.641 46.6399 147.321 46.6399C150.001 46.6499 152.171 48.8199 152.171 51.4999ZM150.551 51.4999C150.551 49.7099 149.101 48.2599 147.311 48.2599C145.521 48.2599 144.071 49.7099 144.071 51.4999C144.071 53.2899 145.521 54.7399 147.311 54.7399C149.101 54.7399 150.551 53.2899 150.551 51.4999Z" fill="#fff" />
                    <path d="M161.95 46.6499C163.49 46.6799 164.72 47.9399 164.72 49.4799C164.72 51.0199 163.49 52.2799 161.95 52.3099H158.37V56.3499H156.75V46.6399L161.95 46.6499ZM163.12 49.4799C163.12 48.8299 162.6 48.2899 161.95 48.2699H158.37V50.6999H161.95C162.6 50.6699 163.12 50.1399 163.12 49.4799Z" fill="#fff" />
                    <path d="M178.671 46.6499V56.3599H177.051V52.0499L174.221 54.7699L171.391 52.0499V56.3599H169.771V46.6499L174.221 51.8199L178.671 46.6499Z" fill="#fff" />
                    <path d="M203.06 46.6499V56.3999L196.99 50.4699V56.3699H195.37V46.6299L201.44 52.5599V46.6599H203.06V46.6499Z" fill="#fff" />
                    <path d="M215.661 46.6499V48.2699H212.501V56.3599H210.881V48.2699H207.721V46.6499H215.661Z" fill="#fff" />
                    <path d="M225.491 53.5699V53.6199C225.491 55.1099 224.281 56.3199 222.781 56.3299H222.351C221.031 56.3299 
                            219.911 55.3899 219.681 54.0899L221.271 53.8099C221.361 54.3299 
                            221.811 54.7099 222.341 54.7099H222.771C223.371 54.7099 223.861 
                            54.2199 223.861 53.6199V53.5699C223.861 53.1199 223.581 52.7099 
                            223.151 52.5499L221.391 51.8899C220.161 51.4299 219.441 50.1599 
                            219.681 48.8699C219.911 47.5799 221.031 46.6499 222.341 46.6499H222.771C223.801 46.6499 224.751 
                            47.2299 225.201 48.1599L223.751 48.8699C223.571 48.4999 223.191 48.2599 222.771 48.2599H222.341C221.811 48.2599 
                            221.361 48.6399 221.271 49.1499C221.181 49.6699 221.471 50.1799 221.961 50.3599L223.721 51.0199C224.791 51.4299
                             225.491 52.4399 225.491 53.5699Z" fill="#fff" />
                    <mask id="mask0_2208_3224" maskUnits="userSpaceOnUse" x="0" y="0" width="56" height="57">
                        <path className="draw" d="M37.67 0H34.87V18.27L20.22 18.31V0H17.43V21.11L37.67 21.07V0Z" fill="#fff" />
                        <path className="draw" d="M8.61 0H5.81V56.05H8.61V0Z" fill="#fff" />
                        <path className="draw" d="M2.8 0H0V56.05H2.8V0Z" fill="#fff" />
                        <path className="draw" d="M49.27 0H46.47V56.05H49.27V0Z" fill="#fff" />
                        <path className="draw" d="M55.08 0H52.28V56.05H55.08V0Z" fill="#fff" />
                        <path className="draw" d="M40.66 23.72H14.42V0H11.62V26.52H43.46V0H40.66V23.72Z" fill="#fff" />
                        <path className="draw" d="M37.65 34.54H17.43V56.05H20.22V37.34H34.85V39.96H23.23V56.05H26.03V42.76H37.65V34.54Z" fill="#fff" />
                        <path className="draw" d="M31.84 0H29.04V12.89H26.03V0H23.24V15.69H31.84V0Z" fill="#fff" />
                        <path className="draw" d="M11.62 56.05H14.42V31.94H40.66V45.36H29.04V56.05H31.84V48.16H34.85V56.05H37.65V48.16H40.66V56.05H43.46V29.14H11.62V56.05Z" fill="#fff" />
                    </mask>
                    <path
                        className={`path ${inView ? "draw-path" : ""}`} d="M1.5 -3V59.5H7V-3H13V25M13 26.5V25M13 25H42M44 25H42M42 25V-3H36.5V19.5M36.5 21V19.5M36.5 19.5H19M16.5 19.5H19M19 19.5V-3H24.5V14.5M24.5 17.5V14.5M24.5 14.5H30.5M32.5 14.5H30.5M30.5 14.5V-3"
                        stroke="#fff" />
                    <path
                        className={`path ${inView ? "draw-path" : ""}`}
                        d="M54 57.5V-3H48V59H42.5V30.5M42.5 29V30.5M42.5 30.5H13M11.5 30.5H13M13 30.5V59H19V36M19 34.5V36M19 36H36.5M38 36H36.5M36.5 36V41.5M36.5 43V41.5M36.5 41.5H24.5M23 41.5H24.5M24.5 41.5V59H30.5V47M30.5 45V47M30.5 47H36.5M41 47H36.5M36.5 47V59"
                        stroke="#fff" />
                </svg>
                <div className='progress'></div>
            </div>
            <div className='img-container'>
                <Image
                    alt='loading'
                    src={isMobile ? "/icons/group-logo-mob.png" : '/icons/logo-group.png'}
                    fill
                    sizes=''
                    priority
                />
            </div>
        </div>
    )
}

export default LoadingPage