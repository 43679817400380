
import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useState } from 'react'
import { isMobileContext } from '../../contexts/isMobileContext'
import { useQuery } from "react-query"
import { BASE_URL, fetchData } from '../../utils/FetchData'
import { AnimatePresence, motion } from "framer-motion";
import Link from 'next/link'
import FadeIn from '../Animations/FadeIn'
const Tomorrow = () => {
    const { isMobile } = useContext(isMobileContext)
    const { locale } = useRouter()
    const [tomorrowSection, setTomorrowSection] = useState();
    const [mainText, setMainText] = useState()
    const { data, isSuccess, isLoading, isError } = useQuery(["tomorrowSection", locale], () =>
        fetchData(
            "tomorrow-sections",
            locale,
            "populate[0]=img_desktop&populate[1]=img_mobile&populate[2]=google_icon_mobile&populate[3]=google_icon_desktop&populate[4]=app_icon_mobile&populate[6]=app_icon_desktop"
        )
    )
    useEffect(() => {
        isSuccess &&
            (setTomorrowSection(data?.data[0]?.attributes),
                setMainText(data?.data[0]?.attributes?.title?.split(" ")))
    }, [data, isSuccess])
    return (
        <div className='tomorrow-section'>
            <AnimatePresence>
                {!isMobile && <>
                    <motion.div
                        initial={{ height: 0 }}
                        whileInView={{ height: "25%" }}
                        transition={{ delay: .1, duration: .35 }}
                        viewport={{ once: true }}
                        className='border-top'
                        key='border-top'
                    ></motion.div>
                    <motion.div
                        initial={{ height: 0 }}
                        whileInView={{ height: "20%" }}
                        transition={{ delay: .1, duration: .35 }}
                        viewport={{ once: true }}
                        className='border-bottom'
                        key='border-bottom'
                    ></motion.div>
                </>}
            </AnimatePresence>
            <FadeIn customClass='header'>
                <h4 className='_eleX'>{tomorrowSection?.title}</h4>
                <p className='_eleX'>{tomorrowSection?.description1}</p>
            </FadeIn>
            <FadeIn customClass='buttons-group'>
                {!isMobile && <p className='_eleX'>{tomorrowSection?.description2}</p>}
                <div className='button _eleX'>
                    <a className='icon'
                        href="https://play.google.com/store/apps/details?id=com.kijamii.hydepark&hl=en&gl=US"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {tomorrowSection && <Image
                            src={`${BASE_URL}${isMobile ? tomorrowSection?.google_icon_mobile?.data?.attributes?.url : tomorrowSection?.google_icon_desktop?.data?.attributes?.url}`}
                            alt={tomorrowSection?.google_icon_desktop?.data?.attributes?.name}
                            objectFit="contain"
                            layout="fill"
                        />}
                    </a>
                </div>
                <div className='button _eleX'>
                    <a className='icon'
                        href="https://apps.apple.com/eg/app/hyde-park-community/id1464522488"
                        target="_blank"
                        rel="noreferrer"
                    >
                        {tomorrowSection && <Image
                            src={`${BASE_URL}${isMobile ? tomorrowSection?.app_icon_mobile?.data?.attributes?.url : tomorrowSection?.app_icon_desktop?.data?.attributes?.url}`}
                            alt={tomorrowSection?.app_icon_desktop?.data?.attributes.name}
                            objectFit="contain"
                            layout="fill"
                        />}
                    </a>
                </div>
            </FadeIn>
            <div className='overlay'></div>
            <div className='background-image'>
                {tomorrowSection && <Image
                    src={BASE_URL + (isMobile ? tomorrowSection?.img_mobile.data?.attributes?.url : tomorrowSection?.img_desktop?.data?.attributes.url)}
                    alt={tomorrowSection?.img_mobile?.data?.attributes.name}
                    layout="fill"
                    objectPosition={"top"}
                    objectFit="cover"
                />}
            </div>
        </div>
    )
}

export default Tomorrow